// TODO Move functionality to the hook(s) and delete file

import ActionType from '../../store/action-type';
import { getStore } from '../../store';

export const setCountry = (payload) => {
  getStore().dispatch({
    type: ActionType.Countries.Pick,
    payload: payload.toUpperCase(),
  });
};

// TODO Use selector instead
export const getCountry = () => getStore().getState().countries.picked;

export const getCountriesList = () => getStore().getState().countries.list;

export const getCountryDataByCode = (code) => {
  const list = getCountriesList();

  return list.find((v) => v.code2 === code);
};
